import ChatIcon from "../../ChatIcon";

const botName = "Educli bot";

const config = {
  botName: botName,
  lang: "en",
  customComponents: {
    botAvatar: (props) => <div className="chat-bot-avatar"><ChatIcon {...props}/></div>
  },
  customStyles: {
    // botMessageBox: {
    //   background:
    //     "linear-gradient(265deg, #FFC200 -33.07%, #FC5B2D 117.43%)",
        
    // },
    chatButton: {
      backgroundColor: "#fc5b2d",
    },
  },
  initialMessages: [],
};

export default config;
