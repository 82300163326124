// @ts-ignore
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";

import config from "./components/config";
import MessageParser from "./components/MessageParser";
import ActionProvider from "./components/ActionProvider";
import Login from "./components/Login";
import { useUserInformation } from "./context/UserInformation";
import { useState } from "react";
import ChatIcon from "./ChatIcon";
import {loggedinUser} from "./components/Provider/InformationProvider";
import "./App.css";

function App() {
  const { user } = useUserInformation();

  return (
    <div className="main-chatbot">
      <div className={`chatbot-wrapper open`}>
        {user ? (
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            validator={(input) => (input.length > 0 ? true : false)}
          headerText={
            <div className="chat-header">
              <ChatIcon /> <span>Conversation with educli bot</span>
              <span class="welcome_text">Hello {user.loggedinUser}!</span>
            </div>
          }

          />
        ) : (
          <Login />
        )}
      </div>
     
    </div>
  );
}

export default App;
