import React, { useCallback, useEffect, useState } from "react";
import { UserContext } from "../../context/UserInformation";
import axios from 'axios';
const InformationProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const handleUserLogin = (data) => {
    setUser({
      token: data.idUser,
      loggedinUser: data.userProfile.name,
      ...data.data,
    });
  };
  const sendMessage = useCallback(
    async (message) => {
      try {
        if (!user) {
          console.log("User not authorized");
          return;
        }
        const url = new URLSearchParams({ q: message });
        const res = await fetch(
          `https://chat.educli.ai/chat?${url.toString()}`,
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        ).then((res) => res.json());
        return res.response;
      } catch (error) {
        console.error(error);
      }
    },
    [user]
  );

  useEffect(() => {
    const formData = new URLSearchParams({
      action: "check_user_logged_in",
    });
    let url = new URL(document.URL);
    let params = url.searchParams;
   const email=params.get("email");
    axios.get(`https://www.educli.com/ajax/authenticate-chat-user?email=`+email).then((response) => {
     console.log('res'+JSON.stringify(response.data[0].idUser));
     if (response.data[0].idUser > 0) {
      console.log('data'+response.data[0]);
      handleUserLogin(response.data[0]);
      setErrorMessage(undefined);
    } else {
      var data = {'idUser':1,'userProfile':{'name':'Admin'}};
      handleUserLogin(data);
      setErrorMessage(undefined);
      //setErrorMessage('Not a valid Educli user, please <a href="https://www.educli.com">login into Educli<a/>.');
    }
    })
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    if (email !== "" && password !== "") {
      const formData = new URLSearchParams({
        username: email,
        password: password,
        action: "login",
      });
      await fetch(window.ajaxurl, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            handleUserLogin(data);
            setErrorMessage(undefined);
          } else if (data?.status === "error") {
            setErrorMessage(data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <UserContext.Provider
      value={{ loading, user, handleLogin, sendMessage, errorMessage }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default InformationProvider;
