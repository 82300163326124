import React from "react";
import { useUserInformation } from "../../context/UserInformation";
import "../../App.css";
const Login = () => {
  const { handleLogin, errorMessage } = useUserInformation();

  return (
    <form onSubmit={handleLogin} className="react-chatbot-kit-chat-container">
      <div className="react-chatbot-kit-chat-inner-container form-main">
        <div className="hide react-chatbot-kit-chat-input-form form-inner-main">
          <input
            className="react-chatbot-kit-chat-input form-control"
            type="text"
            name="email"
            placeholder="Enter your email"
          />
        </div>
        <div className="hide react-chatbot-kit-chat-input-form form-inner-main">
          <input
            className="react-chatbot-kit-chat-input form-control"
            type="password"
            name="password"
            placeholder="Enter your password"
          />
        </div>
        {errorMessage && <span className="error-message">Not a valid Educli user, please <a href="https://www.educli.com">login into Educli</a>.</span>}
        <button type="submit" className="form-button hide">
          Login
        </button>
      </div>
    </form>
  );
};

export default Login;
