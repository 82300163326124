import React from "react";

const ChatIcon = () => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="30"
      viewBox="0 0 512 512"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m317.5 178h-123c-24.537 0-44.5 19.962-44.5 44.5s19.962 44.5 44.5 44.5h123c24.537 0 44.5-19.962 44.5-44.5s-19.963-44.5-44.5-44.5zm-104.664 49.999c0 8.284-6.716 15-15 15s-15-6.716-15-15v-11c0-8.284 6.716-15 15-15s15 6.716 15 15zm116.327 0c0 8.284-6.716 15-15 15s-15-6.716-15-15v-11c0-8.284 6.716-15 15-15s15 6.716 15 15z" />
        <path d="m437 257.997v-28.994c0-100.275-81.248-179.003-181-179.003-99.859 0-181 78.74-181 179.003v28.994c0 46.267 37.741 84.003 84.003 84.003h193.994c46.262 0 84.003-37.741 84.003-84.003zm-242.5 39.002c-41.079 0-74.5-33.42-74.5-74.5s33.42-74.5 74.5-74.5h123c41.079 0 74.5 33.42 74.5 74.5s-33.421 74.5-74.5 74.5z" />
        <path d="m334 402h-33c-16.5 0-30 13.5-30 30s13.5 30 30 30h33c16.5 0 30-13.5 30-30s-13.5-30-30-30z" />
        <path d="m482 356.497c0 33.338-28.856 61.503-63.014 61.503h-26.652c1.079 4.495 1.666 9.179 1.666 14 0 5.541-.771 10.902-2.183 16h27.169c50.419 0 93.014-41.903 93.014-91.503v-29.317c-8.725 6.565-18.922 11.274-30 13.532z" />
        <path d="m467 161.221h-1.298v-86.744c0-8.284-6.716-15-15-15s-15 6.716-15 15v44.348c20.385 32.652 31.298 70.499 31.298 110.178v28.994c0 19.615-4.98 38.089-13.742 54.224h13.742c24.813 0 45-20.187 45-45v-61c0-24.813-20.187-45-45-45z" />
        <path d="m45 229.003c0-39.697 10.913-77.556 31.297-110.212v-44.314c0-8.284-6.716-15-15-15s-15 6.716-15 15v86.744h-1.297c-24.813 0-45 20.187-45 45v61c0 24.813 20.187 45 45 45h13.742c-8.762-16.135-13.742-34.608-13.742-54.224z" />
      </g>
    </svg>
  );
};

export default ChatIcon;
