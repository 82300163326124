import React, { useState } from "react";
import { useUserInformation } from "../context/UserInformation";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [isLoading, setIsloading] = useState(true);
  const { sendMessage } = useUserInformation();
  const send = async (message) => {
    if (message !== "") {
      try {
        setIsloading(true);
        const loader = createChatBotMessage(isLoading);
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, loader],
        }));
        const response = await sendMessage(message);
        if (!response) return;
        const botMsg = createChatBotMessage(response);
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages.filter((msg) => msg !== loader), botMsg],
        }));
      } finally {
        setIsloading(false);
      }
    }
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            send,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
