import { createContext, useContext } from "react";

export const UserContext = createContext({
  userToken: "",
  loggedinUser: "",
  errorMessage: "",
  handleLogin: () => null,
});

export const useUserInformation = () => useContext(UserContext);
